import ApplicationLogo from '@/Components/ApplicationLogo';
import Testimonial from '@/Components/Testimonial';
import { PropsWithChildren } from 'react';

export default function Guest({ children }: PropsWithChildren) {
    const testimonial = {
        feedback:
            "MailWizard exceeded my high expectations with its email quality, especially as an experienced and passionate copywriter myself. Communicating with stakeholders like CEOs demands precision, and MailWizard consistently nails the tone. It's a time-saver that lets me focus on what matters",
        user: {
            name: 'Jelena Puzic',
            role: 'Senior Communication Manager at Grand Hotel Les Trois Rois',
            image: {
                path: '/images/avatar/jelena-puzic.png',
                alt: 'Jelena Puzic',
            },
        },
    };

    return (
        <div className="flex min-h-screen bg-mw-black1">
            <div className="flex flex-col flex-1">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <ApplicationLogo className="h-24" />
                    </div>
                    <div className="mt-10">{children}</div>
                </div>
            </div>

            <div className="relative hidden lg:flex lg:w-1/3 bg-gradient-to-r from-mw-orange to-mw-magenta">
                <div className="w-full">
                    <Testimonial
                        className="fixed bottom-12 xl:bottom-16 mx-8"
                        feedback={testimonial.feedback}
                        user={testimonial.user}
                    />
                </div>
            </div>
        </div>
    );
}
